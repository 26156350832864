html,
body {
  margin: 0;
  padding: 0;
  width: 100vw;
  height: 100vh;
  // font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
  //   Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  color: white;
  font-weight: 900;
  background-color: black;
  overflow: hidden;
}

.app {
  position: absolute;
  width: 100vw;
  height: 100vh;
  display: grid;
  justify-items: center;
  align-items: center;
  top: 0;
  left: 0;
  backdrop-filter: blur(50px) opacity(1) grayscale(100);
  animation: fadeOut 4s forwards;
  background-color: black;

  &.playing {
    animation: fadeIn 4s forwards;
  }
}

@keyframes fadeIn {
  0% {
    background-color: black;
    backdrop-filter: blur(50px) opacity(1) grayscale(100);
  }

  100% {
    background-color: transparent;
    backdrop-filter: blur(0px) opacity(1) grayscale(0);
  }
}

@keyframes fadeInCanvas {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeOutCanvas {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes fadeOut {
  0% {
    background-color: transparent;
    backdrop-filter: blur(0px) opacity(1) grayscale(0);
  }

  100% {
    background-color: black;
    backdrop-filter: blur(50px) opacity(1) grayscale(100);
  }
}

.controls {
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
}

header {
  font-size: 48px;
  padding-bottom: 16px;
  color: white;
  display: none;
}

footer {
  padding-top: 16px;
  // display: none;
}

button {
  width: 80vh;
  height: 80vh;
  border-radius: 999px;
  padding: 30px;
  box-sizing: content-box;
  outline: hidden;
  border: 0;
  background-color: transparent;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
  outline: none;
  // background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='102px' height='98px' viewBox='0 0 52 49' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3EPage 1%3C/title%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Artboard' fill='%23FFFFFF' fill-rule='nonzero'%3E%3Cpath d='M19.29178,1.03596 L30.39069,0.18369 L30.38069,16.619 L39.72179,16.609 C45.5908,16.609 51.10641,18.32323 51.68266,25.5046 C52.11849,30.94752 48.6755,34.59389 45.63923,35.15078 C41.91058,35.15078 43.02919,35.16528 38.06078,35.16528 C39.70237,35.16528 44.54483,32.15812 43.46012,28.03718 C42.45773,24.2407 39.09707,24.16806 35.95431,23.96952 C32.80192,23.77098 19.45605,23.08819 19.45605,23.08819 L19.29141,1.03563' id='path4397'%3E%3C/path%3E%3Cpath d='M4.07198,21.03532 C2.05267,23.47108 0.04305,27.19977 1.19551,31.36913 C4.64818,43.01524 23.18516,48.15309 30.98147,48.46785 L30.07593,34.83633 C30.07593,34.83633 17.4129,36.50213 11.01117,29.79048 C8.2994,26.94796 8.84175,23.96016 10.88527,21.37429 C8.4931,21.26291 5.67963,21.10311 4.07193,21.03532' id='path4399'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  background-size: contain;
  // &:hover {
  //   // background-color: rgba(255, 255, 255, 0.5);
  //   // backdrop-filter: saturate(4);
  //   // background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='102px' height='98px' viewBox='0 0 52 49' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3EPage 1%3C/title%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Artboard' fill='%23FFFFFF' fill-rule='nonzero'%3E%3Cpath d='M19.29178,1.03596 L30.39069,0.18369 L30.38069,16.619 L39.72179,16.609 C45.5908,16.609 51.10641,18.32323 51.68266,25.5046 C52.11849,30.94752 48.6755,34.59389 45.63923,35.15078 C41.91058,35.15078 43.02919,35.16528 38.06078,35.16528 C39.70237,35.16528 44.54483,32.15812 43.46012,28.03718 C42.45773,24.2407 39.09707,24.16806 35.95431,23.96952 C32.80192,23.77098 19.45605,23.08819 19.45605,23.08819 L19.29141,1.03563' id='path4397'%3E%3C/path%3E%3Cpath d='M4.07198,21.03532 C2.05267,23.47108 0.04305,27.19977 1.19551,31.36913 C4.64818,43.01524 23.18516,48.15309 30.98147,48.46785 L30.07593,34.83633 C30.07593,34.83633 17.4129,36.50213 11.01117,29.79048 C8.2994,26.94796 8.84175,23.96016 10.88527,21.37429 C8.4931,21.26291 5.67963,21.10311 4.07193,21.03532' id='path4399'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  // }
}

.overlay {
  &,
  &Playing {
    position: fixed;
    pointer-events: none;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    font-size: 108vh;
    padding-top: 08vh;
    -webkit-text-stroke: 1px white;
    -webkit-text-fill-color: transparent;
    display: grid;
    justify-content: center;
    align-content: center;
    z-index: 0;
    transition: all 1s ease;
    animation: fadeInCanvas 4s forwards;
  }

  &Playing {
    opacity: 0;
    animation: fadeOutCanvas 4s forwards;
  }
}

#canvas {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 999;
  pointer-events: none;
  filter: grayscale(100);
  transition: all 1s ease;
  animation: fadeInCanvas 4s forwards;

  &.isPlaying {
    filter: grayscale(0);
    transition: all 1s ease;
  }
}

// _::-webkit-full-page-media,
// _:future,
// :root body {
//   color: #0000ff;
//   background-color: #cccccc;
// }
